.searchComp {
  margin-top: 20px;
  padding-bottom: 20px;
}

.portfolioItems-container {
  display: flex;
  width: 100%;
}

.portfolioItem {
  flex: 1;
  text-align: center;
  padding: 16px;
}

.portfolioItem button {
  margin: auto;
}

.portfolioItem > div {
  height: 100%;
}

.portfolioItemAdd > div {
  background-color: #dfdfdf;
}

.ctg-table {
  width: 100%;
  float: left;
  min-height: 100%;
}

.ctg-table-enter {
  margin-left: -110%;
}
.ctg-table-enter-active {
  margin-left: 0;
  transition: margin-left 300ms;
}
.ctg-table-enter-done {
  margin-left: 0;
}

.ctg-table-exit {
  margin-left: 0;
}
.ctg-table-exit {
  margin-left: -110%;
  transition: margin-left 300ms;
}
.ctg-table-exit-done {
  margin-left: -110%;
  height: 0;
}

.ctg-percentage {
  display: none;
}
.ctg-percentage.ctg-percentage-enter,
.ctg-percentage.ctg-percentage-enter-done {
  display: block !important;
}
