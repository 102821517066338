body {
  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.message-area {
  box-sizing: border-box;
  padding: 20px;
  background-color: #dfdfdf;
  color: gray;
  width: 100%;
  border-radius: 5px;
}
