@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #ddd 8%, #ccc 18%, #ddd 33%);
  background-size: 800px 104px;
  height: 96px;
  width: 100%;
  position: relative;
}

.data-source {
  z-index: 2;
  position: fixed;
  right: 10px;
  top: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid black;
}

.data-source-details {
  z-index: 900;
}

.data-source-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.data-source-details ul li span {
  margin-left: 5px;
}

.data-source-details ul li span:hover {
  text-decoration: underline;
  cursor: pointer;
}
