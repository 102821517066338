.headerWrapper {
  width: 100%;
}

.headerWrapper .logo {
  float: left;
  cursor: pointer;
}

.headerWrapper .username {
  float: right;
  padding-top: 25px;
}

.headerWrapper .username {
  cursor: pointer;
}
